import useTimezone from "../hooks/useTimezone";

interface IDaterangeProps {
    startDate: unknown;
    endDate: unknown;
    /**
     * datePlaceholder will replace the missing value, when only one of start/end is filled
     *
     * @type {string}
     * @memberof IDaterangeProps
     */
    datePlaceholder?: string;
    /**
     * emptyPlaceholder will be shown, when both startDate and endDate are not filled
     *
     * @type {string}
     * @memberof IDaterangeProps
     */
    emptyPlaceholder?: string;
}

const Daterange = ({ startDate, endDate, datePlaceholder = "", emptyPlaceholder = "" }: IDaterangeProps) => {
    const { formatDate } = useTimezone();

    const hasStart = typeof startDate === "string";
    const hasEnd = typeof endDate === "string";

    if (!hasStart && !hasEnd) {
        return <>{emptyPlaceholder}</>;
    }

    const formattedStart = hasStart ? formatDate(new Date(startDate)) : datePlaceholder;
    const formattedEnd = hasEnd ? formatDate(new Date(endDate)) : datePlaceholder;

    return <>{`${formattedStart} - ${formattedEnd}`}</>;
};

export default Daterange;
